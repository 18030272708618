import { useEffect } from 'react';

import clsx from 'clsx';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { ScrollScene as ScrollSceneOriginal } from 'scrollscene';

import { IProjectItemOverview, IProjectService } from 'common/types/projects';
import Link from 'next/link';
import Image from 'next/image';

const ScrollScene: any = ScrollSceneOriginal;

const createCardsScene = (el: any) => {
  return new ScrollScene({
    triggerElement: document.querySelector('#cards'),
    offset: -300,
    triggerHook: 0.5,
    gsap: { timeline: stackedCardsTl(el) },
    duration: '100%',
  });
};

const stackedCardsTl = (el: any) => {
  const timeline = gsap.timeline({ paused: true });
  timeline.addLabel('in').scaleDown(el);
  return timeline;
};

const stackedCards = (cardClassName: any) => {
  const cards = document.querySelectorAll(cardClassName);
  for (let index = 0; index < cards.length - 1; index++) {
    createCardsScene(cards[index]);
  }
};

export const WorkCaseStudiesCarousel: React.FC<{ services?: string[] }> = ({ services }) => {
  const { t } = useTranslation('projectItemsOverview');
  let projects: IProjectItemOverview[] = t('projectItemsOverview:projectItems', {
    returnObjects: true,
  });
  projects = projects.filter(item => item.active);
  if (services?.length) {
    projects = projects.filter(item =>
      item.services.some(service => services.includes(service.slug)),
    );
  }

  useEffect(() => {
    gsap.registerEffect({
      name: 'scaleDown',
      effect: (targets: any, config: any) => gsap.to(targets, { ease: config.ease, scale: 1 }),
      defaults: { ease: 'power1.out' },
      extendTimeline: true,
    });
    stackedCards('.card');
  }, []);

  const renderArticle = (
    { color, imageHomepage, intro, services, seo, slug, title }: IProjectItemOverview,
    index: number,
  ) => (
    <article
      key={slug}
      className={clsx(
        'group card mb-2',
        'origin-[50% 50%] top-5 w-full rounded-[0] lg:sticky',
        `card-${index}`,

        {
          'bg-gradient-to-br from-[#152243] to-[#0035AD] text-white': color === 'blue',
          'bg-gradient-to-br from-[#935513] to-[#bc8c5c] text-white': color === 'yellow',
          'bg-gradient-to-br from-[#a00000] to-[#D01818] text-white': color === 'red',
          'bg-gradient-to-br from-[#3f1d56] to-[#6D547D] text-white': color === 'purple',
        },
      )}>
      <div className="flex flex-wrap gap-1.5 px-4 pt-6 md:pt-10 lg:px-12 lg:pt-12">
        {services.map((service: IProjectService) => {
          if (service.href) {
            return (
              <Link
                className="z-50 cursor-pointer bg-white/10 p-3 py-2 text-[16px] hover:border-primary hover:text-primary"
                href={service.href}
                onClick={e => {
                  e.stopPropagation();
                }}
                key={service.slug}>
                {service.label}
              </Link>
            );
          }
          return (
            <div
              className="cursor-pointer bg-white/10 p-3 py-2 text-[16px] hover:border-primary hover:text-primary"
              key={service.slug}>
              {service.label}
            </div>
          );
        })}
      </div>
      <div className="flex h-auto flex-col justify-between overflow-hidden pb-0 duration-300 lg:mb-0 lg:pl-12 lg:pr-0 xl:flex-row">
        <div className="relative flex w-full flex-col justify-between px-4 lg:pb-12 xl:w-2/5">
          <div className="pb-10 lg:pb-20">
            <h2
              className="text-heading-work-item mt-4 md:mr-3 lg:mt-6"
              dangerouslySetInnerHTML={{ __html: title }}
            />

            <div className="mb-2 mt-6 opacity-90" dangerouslySetInnerHTML={{ __html: intro }} />
          </div>
          <div className="mt-5 flex items-center">
            {t('common:readMore')}{' '}
            <BsArrowRight
              className="ml-3 mt-1 transition-all duration-300 group-hover:ml-6"
              size={20}
            />
          </div>
        </div>

        <div className="justify-en flex w-full items-end pl-4 xl:w-3/5">
          <Image
            alt={imageHomepage.alt}
            className="h-full w-full max-w-[100%] object-contain object-right-bottom"
            src={imageHomepage.src}
            height={imageHomepage.height}
            width={imageHomepage.width}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
          />
        </div>
      </div>
      <Link
        aria-label={`${t('common:work')} - ${seo.title}`}
        href={slug}
        className="cursor-open absolute bottom-0 left-0 right-0 top-0 "
      />
    </article>
  );

  return (
    <>
      <section id="cards" className="bg-gradient relative py-1">
        <div className="mx-auto w-full max-w-[95vw] 3xl:container">
          {projects.map(renderArticle)}
        </div>
      </section>
    </>
  );
};
